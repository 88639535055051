import React from 'react';
import { Checkpoint } from '../components/Checkpoint';
import { PrivateRoute } from '../components/Shared/PrivateRoute';
import { Router } from '@reach/router';
import { CenteredLayout } from '../components/Layouts/CenteredLayout';
import SeoHeader from '../components/Shared/SeoHeader';

export default function CheckpointPage() {
  return (
    <CenteredLayout>
      <SeoHeader title="Checkpoint" />
      <Router basepath="/checkpoint">
        <PrivateRoute component={Checkpoint} path="/" isCheckpoint />
      </Router>
    </CenteredLayout>
  );
}
