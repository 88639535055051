import { Link, navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { CardWrapper } from '../Shared/Wrapper/CardWrapper';
import { CheckpointForm } from './CheckpointForm';

export function Checkpoint() {
  return (
    <section className="min-h-screen flex items-center justify-center px-6 py-24 bg-primary-3">
      <section className="hidden md:flex md:w-4/12">
        <section className="mx-auto">
          <StaticImage
            className="cursor-pointer hover:opacity-80"
            quality={95}
            formats={['webp', 'auto', 'avif']}
            width={210}
            height={120}
            src={`../assets/images/nstw_logo.png`}
            alt="NSTW Logo"
            placeholder="tracedSVG"
            onClick={() => navigate('/')}
          />
        </section>
      </section>

      <section className="w-full md:w-4/12">
        <CardWrapper>
          <section className="py-6">
            <section className="md:hidden mb-5 text-center">
              <StaticImage
                className="cursor-pointer hover:opacity"
                quality={95}
                formats={['webp', 'auto', 'avif']}
                width={150}
                height={80}
                src={`../assets/images/nstw_logo.png`}
                alt="NSTW Logo"
                placeholder="tracedSVG"
                onClick={() => navigate('/')}
              />
            </section>

            <h1 className="text-xl text-center">
              We need some information about you
            </h1>

            <section className="my-5 md:my-10 min-h-60">
              <CheckpointForm />
            </section>

            <section className="text-center text-xs leading-3">
              By clicking{' '}
              <span className="font-bold">"Complete my Registration"</span> you
              agree to our{' '}
              <Link className="font-bold text-primary-1" to="/terms-of-service">
                Terms of Service
              </Link>{' '}
              and acknowledge that our{' '}
              <Link className="font-bold text-primary-1" to="/privacy-policy">
                Privacy Policy
              </Link>{' '}
              applies to you.
            </section>
          </section>
        </CardWrapper>
      </section>
    </section>
  );
}
